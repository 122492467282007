import React, { useEffect } from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as L from 'leaflet';
import { MapContext } from './MapContext';
import { useState } from 'react'
import i18n from './i18n';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

const MainSnowreport = React.lazy(() => import('./Snowreport/MainSnowreport'));
const MainAlpinemeteo = React.lazy(() => import('./Alpinemeteo/MainAlpinemeteo'));
const MainAvalanchereport = React.lazy(() => import('./Avalanchereport/MainAvalanchereport'));
const MainContact = React.lazy(() => import('./MainContact'));
const RegionMapGen = React.lazy(() => import('./RegionMapGen'));

let instance = null;

const useMatomoTracking = () => {
   const [matomoLoaded, setMatomoLoaded] = useState(false);

   useEffect(() => {
      const loadMatomo = () => {
         const disclaimerAccepted = sessionStorage.getItem("disclaimerAccepted");
         if (disclaimerAccepted && !matomoLoaded) {
            instance = createInstance({
               urlBase: 'https://snowreport-matomo.cr.baeren-lechtal.com/',
               siteId: 1,
               disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
               configurations: { // optional, default value: {}
                  // any valid matomo configuration, all below are optional
                  disableCookies: true
               }
            });
            setMatomoLoaded(true);
         }
      };
      // Initial check when component mounts
      loadMatomo();

      // Listen for storage update events
      window.addEventListener("storageUpdate", loadMatomo);

      return () => {
         window.removeEventListener("storageUpdate", loadMatomo);
      };
   }, [matomoLoaded]);

   return null;
};


const App = () => {
   const [mapState, setMapState] = useState({
      center: [47.2, 11],
      zoom: L.Browser.mobile ? 8 : 9
   });

   dayjs.extend(localeData);
   dayjs.extend(advancedFormat);
   dayjs.locale(i18n.language || 'en');
   useMatomoTracking();

   return (
      <MatomoProvider value={instance}>
         <Suspense>
            <MapContext.Provider value={{ mapState, setMapState }}>
               <Router>
                  <Routes>
                     <Route path="snowreport" exact element={<MainSnowreport />} />
                     <Route path="alpinemeteo" exact element={<MainAlpinemeteo />} />
                     <Route path="avalanchereport" exact element={<MainAvalanchereport />} />
                     <Route path="contact" element={<MainContact />} />
                     <Route path="privacy" element={<MainContact />} />
                     <Route path="imprint" element={<MainContact />} />
                     <Route path="rgmapgen" element={<RegionMapGen />} />
                     <Route path="*" element={<MainSnowreport />} />
                  </Routes>
               </Router>
            </MapContext.Provider>
         </Suspense>
      </MatomoProvider>
   );
};

export default App;